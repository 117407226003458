import * as React from 'react'
import css from './ProductDetailsRow.scss'
import { IProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import MorPriceDisplay from './MorPriceDisplay/MorPriceDisplay'
import CustomProductBadge from '../Search/CustomProductBadge'
import { IMorUnbxdProduct } from '../../../Utils/unbxdUtils'

export interface IProductDetailRowsProps {
  product: IProduct
  storeCode?: string
  displayRatingSummary: boolean
}

const TopProductInfo = (props: IProductDetailRowsProps) => {
  const { product, storeCode } = props
  const [displayRatingSummary, setDisplayRatingSummary] = React.useState(false)

  React.useEffect(() => {
    if (window.BV) {
      const reviewSummary = window.BV?.rating_summary?.data?.find(
        (d: { data: { reviewSummary: any } }) => d?.data?.reviewSummary,
      )
      if (reviewSummary) {
        const numReviews = reviewSummary?.data?.reviewSummary?.numReviews
        if (numReviews && numReviews > 0) {
          setDisplayRatingSummary(true)
        }
      }
    }
  })

  return (
    <>
      <div className={css.thumbnailBadges}>
        <CustomProductBadge
          style={{ marginRight: '7px' }}
          value={(product as IMorUnbxdProduct).DELIVERY_QUALIFICATION}
        />
        <CustomProductBadge value={(product as IMorUnbxdProduct).VENDOR_TYPE} />
      </div>
      <div className={css.coreAttributes}>
        <h1 className={css.nameAttribute}>{product.name}</h1>
        <div className={css.priceAttribute}>
          <MorPriceDisplay product={product} storeCode={storeCode} showSeePriceInCartLabel />
        </div>
        <div
          className={displayRatingSummary ? css.productReview : `${css.productReview} ${css.hidden}`}
          data-bv-show="rating_summary"
          data-bv-product-id={product.sku}
        ></div>
      </div>
    </>
  )
}

export default TopProductInfo
